import { BusinessTypes } from '@canalplus/oneplayer-constants';

import styles from './Spinner.css';

interface IDefaultProps {
  variant?: BusinessTypes.VARIANTS;
}

export type IProps = IDefaultProps;

const Spinner = ({
  variant = BusinessTypes.VARIANTS.MYCANAL,
}: IProps): JSX.Element => {
  return (
    <div className={styles.spinner} data-testid="spinner">
      <span className={styles[`circle--${variant}`]} />
    </div>
  );
};

export { Spinner };
