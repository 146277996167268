import axios from 'axios';

import { logger } from '@canalplus/oneplayer-utils';

/**
 * Fetch the server certificate
 *
 * @param {string} certificateUrl
 * @returns {ArrayBuffer}
 */
async function fetchServerCertificate(
  certificateUrl: string,
  timeout = 10000,
): Promise<ArrayBuffer | null> {
  try {
    const { data } = await axios.get(certificateUrl, {
      responseType: 'arraybuffer',
      timeout,
    });
    return data;
  } catch (e) {
    logger.error('DRM > fetchServerCertificate failed', e);
    return null;
  }
}

export default fetchServerCertificate;
