import { createSelector } from 'reselect';
import { configPreferredLanguageSelector } from './configSelector';
import { livePreferredLanguageSelector, vodPreferredLanguageSelector, } from './coreSelector';
export var multiSelector = function (state) { return state.multi; };
/**
 * @returns {function(object): Array}
 */
export var multiLiveDataSelector = createSelector(multiSelector, function (multi) { return multi.live; });
export var multiPreferredLanguagesSelector = createSelector(livePreferredLanguageSelector, vodPreferredLanguageSelector, configPreferredLanguageSelector, function (live, vod, config) { return ({
    live: live,
    vod: vod,
    config: config,
}); });
