import {
  IAPICredentials,
  IAPICredentialsRaw,
} from '@canalplus/oneplayer-types';

import { logger } from '../logger';
import { isNullOrUndefined } from '../object';

/**
 *
 * @param credentials
 */
function parseCredentials(credentials: IAPICredentialsRaw): IAPICredentials {
  let passToken = isNullOrUndefined(credentials.passToken)
    ? null
    : credentials.passToken;
  if (passToken !== null && typeof passToken !== 'string') {
    logger.error(`You must provide a valid 'passToken' parameter:
            - a 'string'
            Fallback to 'null'
            Provided: '${passToken}'
        `);
    passToken = null;
  }

  let microEligibility = isNullOrUndefined(credentials.microEligibility)
    ? null
    : credentials.microEligibility;
  if (microEligibility !== null && typeof microEligibility !== 'string') {
    logger.error(`You must provide a valid 'microEligibility' parameter:
            - a 'string'
            Fallback to 'null'
            Provided: '${microEligibility}'
        `);
    microEligibility = null;
  }

  let profileId = isNullOrUndefined(credentials.profileId)
    ? '0'
    : String(credentials.profileId);
  if (typeof profileId !== 'string') {
    logger.warnOnce(`'profileId' is badly set:
        - a 'string' (e.g: '0', '1', ...)
        Fallback to '0'
        Provided: ${profileId}
      `);
    profileId = '0';
  }

  let passId = isNullOrUndefined(credentials.passId)
    ? null
    : credentials.passId;
  if (passId !== null && typeof passId !== 'string') {
    logger.warnOnce(`'passId' is badly set:
        - a 'string'
        Fallback to 'null'
        Provided: '${passId}'
      `);
    passId = null;
  }

  let tokenCMS = isNullOrUndefined(credentials.tokenCMS)
    ? null
    : credentials.tokenCMS;
  if (tokenCMS !== null && typeof tokenCMS !== 'string') {
    logger.warnOnce(`'tokenCMS' is badly set:
        - a 'string'
        Fallback to 'null'
        Provided: '${tokenCMS}'
      `);
    tokenCMS = null;
  }

  let deviceId = isNullOrUndefined(credentials.deviceId)
    ? null
    : credentials.deviceId;
  if (deviceId !== null && typeof deviceId !== 'string') {
    logger.warnOnce(`'deviceId' is badly set:
        - a 'string'
        Fallback to 'null'
        Provided: '${deviceId}'
      `);
    deviceId = null;
  }

  let trackingKeyId = isNullOrUndefined(credentials.trackingKeyId)
    ? null
    : credentials.trackingKeyId;
  if (trackingKeyId !== null && typeof trackingKeyId !== 'string') {
    logger.warnOnce(`'trackingKeyId' is badly set:
        - a 'string'
        Fallback to 'null'
        Provided: '${trackingKeyId}'
      `);
    trackingKeyId = null;
  }

  let appSessionId = isNullOrUndefined(credentials.appSessionId)
    ? null
    : credentials.appSessionId;
  if (appSessionId !== null && typeof appSessionId !== 'string') {
    logger.warnOnce(`'appSessionId' is badly set:
        - a 'string'
        Fallback to 'null'
        Provided: '${appSessionId}'
      `);
    appSessionId = null;
  }

  let abTestPopulation = isNullOrUndefined(credentials.abTestPopulation)
    ? null
    : credentials.abTestPopulation;
  if (abTestPopulation !== null && typeof abTestPopulation !== 'string') {
    logger.error(`You must provide a valid 'abTestPopulation' parameter:
      - a 'string'
      Fallback to 'null'
      Provided: '${abTestPopulation}'
    `);
    abTestPopulation = null;
  }

  let msd = isNullOrUndefined(credentials.msd) ? null : credentials.msd;
  if (msd !== null && typeof msd !== 'string') {
    logger.error(`You must provide a valid 'msd' parameter:
      - a 'string'
      Fallback to 'null'
      Provided: '${msd}'
    `);
    msd = null;
  }

  let parentalCode = isNullOrUndefined(credentials.parentalCode)
    ? null
    : credentials.parentalCode;
  if (parentalCode) {
    if (
      isNullOrUndefined(parentalCode.exists) ||
      typeof parentalCode.exists !== 'boolean'
    ) {
      logger.error(`You must provide a valid 'parentalCode.exists' parameter:
      - a 'boolean'
      Fallback 'parentalCode' to 'null'
      Provided: '${parentalCode.exists}'
    `);
      parentalCode = null;
    } else if (
      isNullOrUndefined(
        parentalCode.isParentalCodeActivatedForUserOverloadableContents,
      ) ||
      typeof parentalCode.isParentalCodeActivatedForUserOverloadableContents !==
        'boolean'
    ) {
      logger.error(`You must provide a valid 'parentalCode.isParentalCodeActivatedForUserOverloadableContents' parameter:
      - a 'boolean'
      Fallback 'parentalCode' to 'null'
      Provided: '${parentalCode.isParentalCodeActivatedForUserOverloadableContents}'
    `);
      parentalCode = null;
    }
  }

  return {
    passToken,
    microEligibility,
    profileId,
    passId,
    tokenCMS,
    deviceId,
    trackingKeyId,
    appSessionId,
    abTestPopulation,
    msd,
    parentalCode,
  };
}

export default parseCredentials;
